import http from "../../utils/http"
import Cookies from "js-cookie";

/**
 * 登录832
 */
export function authfupinlogin(params) {
    let website_id = Cookies.get('website_id');
    let website_type = Cookies.get('website_type');
    if (website_id && website_type) {
        params = {
            ...params,
            website_id: website_id,
            website_type: website_type
        }
    }
    // console.log('params', params);
    // return
    return http({
        url: "/api/member/authfupin",
        data: params
    })
}

/**
 * 预算单位授权验证
 */
export function authCoop(params) {
    return http({
        url: `/api/member/authCoop?username=${params.username}&password=${params.password}&token=${params.token}`,
        data: params
    }, 10000, 'get')
}

/**
 * 获取会员信息【进入首页调用了，不能控制登录】
 */
export function memberDetail(params) {
    return http({
        url: "/api/member/detail",
        data: params
    })
}

/**
 * 获取地址列表
 */
export function addressList(params) {
    return http({
        url: "/api/memberaddress/page",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券订单 / 购买
 */
export function voucherOrderbuy(params) {
    return http({
        url: "/fupin/api/voucherOrder/buy",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券订单 / 列表
 */
export function voucherOrderlists(params) {
    return http({
        url: "/fupin/api/voucherOrder/lists",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券订单 审核记录  luoll  21-07-28
 */
export function getAuditReasonsList(params) {
    return http({
        url: "/fupin/api/voucher/getAuditReasonsList",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券订单 / 列表
 */
export function voucherOrderinvalid(params) {
    return http({
        url: "/fupin/admin/voucherorder/invalid",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券订单 / 详情
 */
export function voucherOrderInfo(params) {
    return http({
        url: "/fupin/api/voucherOrder/info",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券订单 / 编辑
 */
export function voucherOrderEdit(params) {
    return http({
        url: "/fupin/api/voucherOrder/edit",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券发票 / 列表
 */
export function VoucherInvoicelists(params) {
    return http({
        url: "/fupin/api/VoucherInvoice/lists",
        data: params,
        forceLogin: true
    })
}
/**
 * 用户领取的消费券 / 列表
 */
export function voucherVoucherList(params) {
    return http({
        url: "/fupin/api/Voucher/voucherList",
        data: params,
        forceLogin: true
    })
}
/**
 * 领取消费券
 * */
export function VoucherreceiveVoucher(params) {
    return http({
        url: "/fupin/api/Voucher/receiveVoucher",
        data: params,
        forceLogin: true
    })
}
/**
 * 企业订单消费券列表
 * */
export function orgVoucherList(params) {
    return http({
        url: "/fupin/api/voucher/orgVoucherList",
        data: params,
        forceLogin: true
    })
}
/**
 *  消费券使用记录
 * */
export function voucherBill(params) {
    return http({
        url: "/fupin/api/Voucher/voucherBill",
        data: params,
        forceLogin: true
    })
}
/**
 *  银行对公信息
 * */
export function sysBankAccount(params) {
    return http({
        url: "/fupin/api/voucherOrder/sysBankAccount",
        data: params,
        forceLogin: true
    })
}
/**
 *  上传支付凭证
 * */
export function voucherOrderPay(params) {
    return http({
        url: "/fupin/api/voucherOrder/pay",
        data: params,
        forceLogin: true
    })
}

/**
 * 消费券发票 / 申请开票
 */
export function VoucherInvoiceapply(params) {
    return http({
        url: "/fupin/api/VoucherInvoice/apply",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券 / 导出券码
 */
export function exportVoucher(params) {
    return http({
        url: "/fupin/api/voucher/exportVoucher",
        data: params,
        forceLogin: true
    })
}

/**
 * 设为默认
 */
export function setDefault(params) {
    return http({
        url: "/api/memberaddress/setdefault",
        data: params,
        forceLogin: true
    })
}

/**
 * 删除地址
 */
export function deleteAddress(params) {
    return http({
        url: "/api/memberaddress/delete",
        data: params,
        forceLogin: true
    })
}

/**
 * 地址信息
 */
export function addressInfo(params) {
    return http({
        url: "/api/memberaddress/info",
        data: params,
        forceLogin: true
    })
}

/**
 * 添加地址
 */
export function saveAddress(params) {
    return http({
        url: "/api/memberaddress/" + params.url,
        data: params,
        forceLogin: true
    })
}

/**
 * 获取优惠券列表
 */
export function couponList(params) {
    let url = ""
    if (params.couponsource == "1") {
        url = "/coupon/api/coupon/memberpage"
    } else {
        url = "/platformcoupon/api/platformcoupon/memberpage"
    }
    return http({
        url: url,
        data: params,
        forceLogin: true
    })
}

/**
 * 我的足迹
 */
export function footPrint(params) {
    return http({
        url: "/api/goodsbrowse/page",
        data: params,
        forceLogin: true
    })
}

/**
 * 删除时间线
 */
export function delFootprint(params) {
    return http({
        url: "/api/goodsbrowse/delete",
        data: params,
        forceLogin: true
    })
}

/**
 * 账户列表
 */
export function accountList(params) {
    return http({
        url: "/api/memberbankaccount/page",
        data: params,
    })
}

/**
 * 设置默认账户
 */
export function accountDefault(params) {
    return http({
        url: "/api/memberbankaccount/setdefault",
        data: params,
    })
}

/**
 * 删除账户
 */
export function delAccount(params) {
    return http({
        url: "/api/memberbankaccount/delete",
        data: params,
    })
}

/**
 * 获取转账方式
 */
export function transferType(params) {
    return http({
        url: "/api/memberwithdraw/transferType",
        data: params,
    })
}

/**
 * 获取账户详情
 */
export function accountDetail(params) {
    return http({
        url: "/api/memberbankaccount/info",
        data: params,
    })
}

/**
 * 保存
 */
export function saveAccount(params) {
    return http({
        url: "/api/memberbankaccount/" + params.url,
        data: params,
    })
}

/**
 * 购买消费券获取发票信息 luoll 21-07-27
 */
export function getGeneralList(params) {
    return http({
        url: "api/invoiceInfo/getGeneralList",
        data: params,
    })
}

/**
 * 发票信息列表：api/invoice/lists
 */
export function listsInvoice(params) {
    return http({
        url: "/api/invoiceInfo/lists",
        data: params,
        forceLogin: true
    })
}
/**
 * 添加发票信息：api/invoice/create
 * 注意：增值税专票只能添加一条，不允许删除，可以修改
 */
export function createInvoice(params) {
    return http({
        url: "/api/invoiceInfo/create",
        data: params,
        forceLogin: true
    })
}
/**
 * 修改发票信息：api/invoice/update
 */
export function updateInvoice(params) {
    return http({
        url: "/api/invoiceInfo/update",
        data: params,
        forceLogin: true
    })
}
/**
 * 删除发票信息：api/invoice/delete
 */
export function deleteInvoice(params) {
    return http({
        url: "/api/invoiceInfo/delete",
        data: params,
        forceLogin: true
    })
}
/**
 * 设置发票信息默认项：api/invoiceInfo/setDefault
 * 参数：invoice_id，is_default [0,1]
 */
export function setDefaultInvoice(params) {
    return http({
        url: "/api/invoiceInfo/setDefault",
        data: params,
        forceLogin: true
    })
}
/**
 * 批量给会员发放消费券
 */
export function batchSendToMember(params) {
    return http({
        url: "/fupin/api/Voucher/batchSendToMember",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券发放记录
 */
export function couponIssuanceRecord(params) {
    return http({
        url: "/fupin/api/Voucher/giveList",
        data: params,
        forceLogin: true
    })
}
/**
 * 获取券码
 */
export function getFacePrice(params) {
    return http({
        url: "/fupin/api/Voucher/getFacePrice",
        data: params,
        forceLogin: true
    })
}
/**
 * 我的消费券列表
 */
export function issueVoucherList(params) {
    return http({
        url: "/fupin/api/voucher/voucherList",
        data: params,
        forceLogin: true
    })
}
/**
 * 消费券发放会员列表
 */
export function issueMemberList(params) {
    return http({
        url: "/fupin/api/voucher/memberList",
        data: params,
        forceLogin: true
    })
}

/**
 * 消费券验证码激活
 */
export function activateByCode(params) {
    return http({
        url: "/fupin/api/voucher/activateByCode",
        data: params,
        forceLogin: true
    })
}

