<template>
    <div>
        <div class="header" v-if="navIsShow">
            <ns-header-top />
            <ns-header-mid />
            <div class="nav-box">
                <div class="nav">
                    <div class="shop-sort" @mouseover="shopHover" @mouseleave="shopLeave">
                        <router-link to="/category"><img src="@/assets/images/list.png" style="width:19px;height:16px;margin-right:10px" />所有商品分类</router-link>
                        <!--					<i class="iconfont iconweibiaoti35"></i>-->
                    </div>
                    <div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'" @mouseover="shopHover" @mouseleave="shopLeave">
                        <div class="shop-box">
                            <div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1">
                                <img class="list-item-icon" v-if="selectedCategory == item.category_id" :src="$img(item.image1)" alt="" />
                                <img class="list-item-icon" v-else :src="$img(item.image)" alt="" />
                                <router-link class="list-item-lk" :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }" target="_blank">
                                    {{ item.category_name }}
                                    <i data-v-498142aa="" class="iconfont iconarrow-right"></i>
                                </router-link>
                                <div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
                                    <div class="cate-part-col1">
                                        <!-- <div class="cate-channel">
										<router-link class="cate-channel-lk" to="/brand" target="_blank">
											手机城
											<i class="el-icon-arrow-right" aria-hidden="true"></i>
										</router-link>
					  </div>-->
                                        <div class="cate-detail">
                                            <dl class="cate-detail-item" v-for="(second_item, second_index) in item.child_list" :key="second_index">
                                                <dt class="cate-detail-tit">
                                                    <router-link target="_blank" class="cate-detail-tit-lk" :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }">
                                                        {{ second_item.category_name }}
                                                        <!--											<i class="iconfont iconarrow-right"></i>-->
                                                        <i></i>
                                                    </router-link>
                                                </dt>
                                                <dd class="cate-detail-con" v-if="second_item.child_list">
                                                    <router-link v-for="(third_item, third_index) in second_item.child_list" :key="third_index" target="_blank" class="cate-detail-con-lk" :to="{ path: '/list', query: { category_id: third_item.category_id, level: third_item.level } }">
                                                        {{ third_item.category_name }}
                                                    </router-link>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <!-- <div class="sub-class-right">
									<div class="adv-promotions">
										<router-link title="" to="" target="_blank">
											<img src="" />
										</router-link>
									</div>
					</div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav>
                        <ul>
                            <li v-for="(nav_item, nav_index) in navList" :key="nav_index" :class="nav_item.url == navSelect ? 'router-link-active' : ''" @click="navUrl(nav_item.url, nav_item.is_blank)">
                                <span>
                                    {{ nav_item.nav_title }}
                                    <i class="line"></i>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="header" v-else>
            <ns-header-top />
            <ns-header-mid />
        </div>
    </div>
</template>

<script>
    import NsHeaderTop from "./NsHeaderTop"
    import NsHeaderMid from "./NsHeaderMid"
    import { tree , tree2 } from "@/api/goods/goodscategory"
    import { navList } from "@/api/website"
    import Cookies from "js-cookie"

    export default {
        props: {
            forceExpand: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                navIsShow: true, // 是否显示navlist
                isShopHover: false,
                isIndex: false,
                thisRoute: "",
                goodsCategoryTree: [],
                selectedCategory: -1,
                navList: [],
                navSelect: "",
                iconArr: ["//image3.suning.cn/uimg/cms/img/159521467494115353.png", "//image3.suning.cn/uimg/cms/img/159521553751456837.png", "//image1.suning.cn/uimg/cms/img/159521577313810168.png", "//image3.suning.cn/uimg/cms/img/159521436499527653.png", "//image2.suning.cn/uimg/cms/img/159521571065832724.png", "//image2.suning.cn/uimg/cms/img/159823778233607035.png", "//image5.suning.cn/uimg/cms/img/159823772695221015.png", "//image5.suning.cn/uimg/cms/img/159521583003884630.png"]
            }
        },
        components: {
            NsHeaderTop,
            NsHeaderMid
        },
        computed: {},
        beforeCreate() {},
        created() {
            this.$store.dispatch("cart/cart_count")
            this.getTree()
            this.nav()
            if (this.$route.path == "/" || this.$route.path == "/index") this.isIndex = true
            this.getWebSiteInfo()
        },
        mounted() {},
        watch: {
            $route: function(curr) {
                this.initNav(curr.path)
                if (curr.path == "/" || curr.path == "/index") {
                    this.isIndex = true
                } else {
                    this.isIndex = false
                }

                if (curr.path == "/list") {
                    this.navSelect = ""
                }
            }
        },
        methods: {
            // 获取分站信息
            getWebSiteInfo() {
                let website_id = Cookies.get("website_id")
                let website_type = Cookies.get("website_type")
                // 当前分站是分站时
                if (website_id && parseInt(website_type) > 1) {
                    this.navIsShow = false
                } else {
                    this.navIsShow = true
                }
            },
            getTree() {
                /*tree({
                    level: 3,
                    template: 2
                })
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.goodsCategoryTree = res.data
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })*/
                tree2({
                    level: 3,
                    template: 2
                })
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.goodsCategoryTree = res.data
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            nav() {
                navList({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            // console.log('导航菜单', res.data.list)
                            this.navList = res.data.list
                            for (let i in this.navList) {
                                this.navList[i]["url"] = JSON.parse(this.navList[i]["nav_url"]).url
                            }
                            this.initNav(this.$route.path)
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            navUrl(url, target) {
                console.log("!url", url)
                if (!url) return
                if (url.indexOf("http") == -1) {
                    if (target) {
                        this.$router.pushToTab({ path: url })
                    } else {
                        this.$router.push({ path: url })
                    }
                } else {
                    if (target) {
                        window.open(url)
                    } else {
                        window.location.href = url
                    }
                }
            },
            initNav(path) {
                for (let i in this.navList) {
                    if (this.navList[i]["url"] == path) {
                        // console.log('path',path)
                        this.navSelect = path
                        continue
                    }
                }
            },
            //鼠标移入显示商品分类
            shopHover() {
                this.isShopHover = true
            },
            //鼠标移出商品分类隐藏
            shopLeave() {
                this.isShopHover = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .header {
        width: 100%;
        height: 192px !important;
        background-color: #fff;

        .shadow {
            box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
        }

        .nav-box {
            width: 100%;
            background-color: $base-color;
            height: 47px;
            margin: 0 auto;
            position: relative;
            float:left;
            .nav {
                width: 1210px;
                height: 47px;
                margin: 0 auto;
                position: relative;
                .shop-sort {
                    width: 210px;
                    height: 47px;
                    line-height: 47px;
                    color: #fff;
                    background: $base-color-nav;
                    float: left;
                    padding: 0px;
                    box-sizing: border-box;
                    text-align: center;
                    a {
                        font-size: 16px;
                        line-height: 47px;
                        color: #fff;
                    }
                }
                .shop-list {
                    width: 1210px;
                    height: 436px;
                    position: absolute;
                    left: 0;
                    top: 47px;
                    display: none;
                    padding: 0;
                    overflow: hidden;
                    .shop-box {
                        width: 210px;
                        height: 436px;
                        box-sizing: border-box;
                        background: rgb(51 51 51 / 80%);
                        font-size: $ns-font-size-base;
                        z-index: 10;
                        color: #fff;
                        display: block;
                        position: relative;

                        a:hover {
                            color: $base-color;
                        }

                        .list-item {
                            padding-left: 12px;
                            height: 72px;
                            line-height: 72px;

                            .list-item-icon {
                                width: 24px;
                                height: 24px;
                                margin-right: 8px;
                                margin-top: 23px;
                                margin-left: 30px;
                                float: left;
                            }

                            .list-item-lk {
                                color: #fff;
                                font-size: 18px;
                                white-space: nowrap;
                                overflow: hidden;
                                /*text-overflow: ellipsis;*/
                                width: 55%;
                                display: inline-block;

                                i {
                                    font-size: 13px;
                                }
                            }

                            &:hover {
                                background-color: #fff;
                                -webkit-transition: 0.2s ease-in-out;
                                -moz-transition: -webkit-transform 0.2s ease-in-out;
                                -moz-transition: 0.2s ease-in-out;
                                transition: 0.2s ease-in-out;

                                .list-item-lk {
                                    color: #301d1d;
                                }
                            }

                            .cate-part {
                                display: none;
                                position: absolute;
                                left: 209px;
                                top: 0px;
                                z-index: auto;
                                width: 1000px;
                                min-height: 436px;
                                background-color: #fff;
                                /*border: 1px solid #f7f7f7;*/
                                -webkit-box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.3);
                                -moz-box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.3);
                                box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.3);
                                -webkit-transition: top 0.25s ease;
                                -o-transition: top 0.25s ease;
                                -moz-transition: top 0.25s ease;
                                transition: top 0.25s ease;

                                &.show {
                                    display: block;
                                }
                            }

                            .cate-part-col1 {
                                float: left;
                                width: 970px;
                                padding: 20px 20px 40px 0px;
                                // .cate-channel {
                                // 	display: block;
                                // 	overflow: hidden;
                                // 	padding-left: 20px;
                                // 	height: 24px;
                                // 	.cate-channel-lk {
                                // 		height: 24px;
                                // 		float: left;
                                // 		padding: 0 10px;
                                // 		margin-right: 10px;
                                // 		background-color: #7c7171;
                                // 		line-height: 24px;
                                // 		color: #fff;
                                // 	}
                                // }
                                .cate-detail-item {
                                    position: relative;
                                    min-height: 40px;
                                    line-height: 40px;
                                    padding-left: 30px;

                                    .cate-detail-tit {
                                        position: absolute;
                                        top: -8px;
                                        left: 0;
                                        display: block;
                                        width: 180px;
                                        padding-left: 30px;

                                        .cate-detail-tit-lk {
                                            color: #3e3a39;
                                            font-size: 16px;
                                            font-weight: 600;

                                            i {
                                                height: 12px;
                                                line-height: 12px;
                                                border-right: 1px solid #0000004d;
                                                float: right;
                                                margin-left: 20px;
                                                margin-top: 12px;
                                            }
                                        }
                                    }

                                    .cate-detail-con {
                                        display: block;
                                        overflow: hidden;
                                        padding: 3px 0;
                                        margin-left: 200px;

                                        .cate-detail-con-lk {
                                            float: left;
                                            font-size: 15px;
                                            color: #3e3a39;
                                            display: inline-block;
                                            margin: 0 15px 8px 0;
                                            height: 16px;
                                            line-height: 16px;
                                            max-width: 100px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            word-wrap: normal;
                                            /*&:not(:first-of-type){*/
                                            /*	margin-left: 10px;*/
                                            /*}*/
                                            &:last-child {
                                                border: none;
                                            }
                                        }
                                    }

                                    &:first-child .cate-detail-con {
                                        border-top: none;
                                    }
                                }
                            }

                            // .sub-class-right {
                            // 	display: block;
                            // 	width: 240px;
                            // 	height: 439px;
                            // 	float: right;
                            // 	border-left: solid 1px #e6e6e6;
                            // 	overflow: hidden;
                            // 	.adv-promotions {
                            // 		display: block;
                            // 		height: 441px;
                            // 		margin: -1px 0;
                            // 		a {
                            // 			background: #fff;
                            // 			display: block;
                            // 			width: 240px;
                            // 			height: 146px;
                            // 			border-top: solid 1px #e6e6e6;
                            // 			img {
                            // 				background: #d3d3d3;
                            // 				width: 240px;
                            // 				height: 146px;
                            // 			}
                            // 		}
                            // 	}
                            // }
                        }
                    }
                }
                .shop-list-active {
                    display: block;
                }
                nav {
                    width: 1000px;
                    height: 47px;
                    line-height: 47px;
                    float: left;
                    font-size: 17px;
                    ul {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        li {
                            cursor: pointer;
                            list-style: none;
                            float: left;
                            padding: 0 20px;
                            color: $base-color-f;
                            min-width: 50px;
                            text-align: center;
                            a {
                                &:hover {
                                    color: $base-color-hover;
                                }
                            }
                            span {
                                .line {
                                    width: 18px;
                                    border-bottom: 4px solid #fff;
                                    border-radius: 8px;
                                    display: none;
                                    margin: -4px auto 0;
                                }
                            }
                        }
                        li:hover {
                            color: $base-color-hover;
                            font-size: 17px;
                            font-weight: 600;
                        }
                        li:first-child {
                            padding-left: 35px;
                        }
                        .router-link-active {
                            color: $base-color-hover;
                            font-size: 17px;
                            font-weight: 600;
                            .line {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
